<template>
  <v-container fluid>
    <PageHeaderSlot :showBackButton="sid !== null"> </PageHeaderSlot>

    <Datatable
      :isLoading="tableLoading"
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page="tablePage"
      :pageLimit="tableLimit"
      :itemTotal="tableItemTotal"
      enableSearch
      @search-clicked="searchKeyword($event)"
      @search-input="tableSearchKey = $event"
      enableClearAll
      @all-search-cleared="clearSearch()"
      @refresh-clicked="refreshData()"
      enableRefresh
      @options-update="onTableOptionsChange($event)"
      enableSearchDate
      datePlaceholder="searchOrderDate"
      :enableExport="$route.name !== 'StudentUserOrderList'"
      @date-selected="searchOrderDate"
      @export-clicked="exportData()"
      :initSearchKeyword="querySearch"
      :enableMultiTypeSearch="!disabledMultiTypeSearch"
      :searchTypeOptions="searchTypeOptions"
      :searchType.sync="tableSearchType"
      @export-doc-clicked="exportInvoice($event, 0)"
      :downloadLoading="downloadLoading"
    >
      <template v-slot:[`item.order_items`]="{ item }">
        <span v-if="typeof item.order_items === 'string'">{{ item.order_items }}</span>
        <span v-else>
          <div v-for="(item, i) in item.order_items" :key="`order-item-${i}`">{{ item }}</div>
        </span>
      </template>
      <template v-slot:[`item.actionViewPayment`]="{ item }">
        <v-btn
          v-if="item.payment_image"
          depressed
          text
          color="primary"
          :ripple="false"
          class="pa-0"
          :href="`${$mediaPath}${item.payment_image}`"
          target="_blank"
        >
          <v-icon class="fi fi-rr-eye mr-1" size="18"></v-icon>
          {{ $t('viewPaymentRecord') }}
        </v-btn>
      </template>
      <template v-slot:[`item.actionRefund`]="{ item }">
        <v-btn
          v-if="item.refund"
          class="colorGrey colorWhite--text"
          depressed
          text
          @click.prevent="openRefundDetails(item.id)"
          >{{ $t('refunded') }}</v-btn
        >
        <v-btn v-else class="primary" depressed text @click.prevent="openRefund(item.id)">{{ $t('refund') }}</v-btn>
      </template>
    </Datatable>

    <ConfirmDialog
      ref="confirmRefundDialog"
      title="message.confirmRefund"
      deleteDescription="message.confirmRefundMsg"
      enableShowRemindCheckbox
    />

    <FormDialog
      ref="refundFormDialog"
      formRef="refundForm"
      title="refund"
      :maxWidth="910"
      @submit-clicked="handleRefundSubmit()"
    >
      <v-row class="ma-0">
        <v-col cols="12" sm="6" md="4">
          <FormInput
            label="refundPrice"
            placeholder="refundPrice"
            :fieldValue.sync="refundData.price"
            dense
            required
            type="number"
            prefix="HK$"
          />
        </v-col>
        <v-col cols="12" v-if="refundOrderType === 'course' || refundOrderType === 'product'">
          <Datatable
            tableName="refundItems"
            :tableData="refundTableData"
            :tableHeaders="refundTableHeaders"
            disableFooter
            disablePagination
            enableSelectToggle
            :selectedTableRow.sync="refundData.items"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="refundOrderType === 'package'">
          <FormInput
            label="refundLessonCount"
            placeholder="refundLessonCount"
            :fieldValue.sync="refundData.lesson_count"
            dense
            required
            type="number"
            isIntegerOnly
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <FormInput label="remark" placeholder="remark" :fieldValue.sync="refundData.remark" dense />
        </v-col>
      </v-row>
    </FormDialog>

    <DialogLayout
      :openDialog="refundDetailsDialogOpen"
      :maxWidth="910"
      title="refund"
      hideAction
      enableScroll
      @close="refundDetailsDialogOpen = false"
    >
      <v-row class="ma-0">
        <v-col cols="12" sm="6" md="4">
          <label class="input-label">{{ $t('refundPrice') }}</label>
          <div>HK${{ $formatter.separator(refundData.price) }}</div>
        </v-col>
        <v-col cols="12" v-if="refundOrderType === 'course' || refundOrderType === 'product'">
          <Datatable
            tableName="refundItems"
            :tableData="refundTableData"
            :tableHeaders="refundTableHeaders"
            disableFooter
            disablePagination
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="refundOrderType === 'package'">
          <label class="input-label">{{ $t('refundLessonCount') }}</label>
          <div>{{ refundData.lesson_count }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label class="input-label">{{ $t('remark') }}</label>
          <div>{{ refundData.remark || '-' }}</div>
        </v-col>
        <v-col cols="12">
          <v-btn class="primary" depressed text @click.prevent="downloadRefundInvoice()" :loading="refundDownloading">{{
            $t('downloadRefund')
          }}</v-btn>
        </v-col>
      </v-row>
    </DialogLayout>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import ExcelMixin from '@/mixins/ExcelMixin.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FormDialog from '@/components/FormDialog.vue'
import FormInput from '@/components/formField/FormInput.vue'
import { cannotAccessList } from '@/assets/cannotAccessList'
import DialogLayout from '@/components/layout/DialogLayout.vue'

export default {
  name: 'OrderList',
  mixins: [ExcelMixin],
  components: {
    Datatable,
    ConfirmDialog,
    FormDialog,
    FormInput,
    DialogLayout,
  },
  computed: {
    query() {
      return this.$route.query
    },
    querySearch() {
      return this.$route.query.search || ''
    },
    refundTableHeaders() {
      if (this.refundOrderType === 'course') {
        return [
          { value: 'course_name', text: this.$t('purchaseItem'), width: 150 },
          { value: 'full_date', text: this.$t('date'), width: 145 }, // need 'date'
          { value: 'weekday', text: this.$t('weekday'), width: 145 },
          { value: 'timeslot', text: this.$t('time') },
        ]
      } else if (this.refundOrderType === 'product') {
        return [
          { value: 'product_name', text: this.$t('purchaseItem'), width: 300 },
          { value: 'qty', text: this.$t('quantity') },
        ]
      }
    },
    tableHeaders() {
      const headers = [
        { value: 'order_id', text: this.$t('orderCode'), width: 100 },
        { value: 'create_date', text: this.$t('createDate'), width: 130 },
        { value: 'student_name', text: this.$t('studentName'), width: 130 },
        { value: 'order_type', text: this.$t('orderType'), width: 100 },
        { value: 'month', text: this.$t('month') },
        { value: 'order_items', text: this.$t('item'), width: 300 },
        { value: 'qty', text: this.$t('quantity') },
        { value: 'total_price', text: this.$t('totalPrice'), width: 150 },
        { value: 'remark', text: this.$t('remark'), width: 200 },
        { value: 'actionViewPayment', text: '', align: 'end' },
        { value: 'actionExportDoc', text: '', align: 'end', btnTitle: 'downloadInvoice' },
      ]
      if (this.canRefund) {
        headers.push({ value: 'actionRefund', text: '', align: 'end' })
      }
      return headers
    },
    sid() {
      const id = parseInt(this.$route.params.sid)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    disabledMultiTypeSearch() {
      return this.$route.name === 'StudentUserOrderList' || this.sid !== null;
    }
  },
  data: vm => ({
    tableLoading: false,
    tableData: [],
    tablePage: 1,
    tableLimit: 10,
    tableItemTotal: 0,
    tableSearchKey: '',
    tableSearchType: 'student_name',
    tableSearchOrderDate: '',

    exportLoading: false,
    exportTimeOut: null,
    downloadLoading: false,
    downloadTimeOut: null,

    refundData: {
      price: '',
      items: [],
      remark: '',
      lesson_count: '',
      order_id: -1,
    },
    refundOrderType: '',
    refundTableData: [],
    refundDetailsDialogOpen: false,
    refundDownloading: false,

    // --- static data
    searchTypeOptions: [
      { value: 'student_name', text: vm.$t('studentName') },
      { value: 'order_id', text: vm.$t('orderCode') },
      { value: 'phone', text: vm.$t('phone') },
    ],

    canRefund: true,
  }),
  methods: {
    async getPayload() {
      let payload = {
        filter_limit: this.tableLimit,
        filter_page: this.tablePage - 1,
        filter_item: [],
      }

      const userData = this.getCurrentUserData()
      if (userData.user_type === 'student') {
        payload.filter_item.push({ key: 'student_id', value: userData.id })
      } else {
        if (this.sid !== null) {
          payload.filter_item.push({ key: 'student_id', value: this.sid })
        }
      }

      if (this.$validate.DataValid(this.tableSearchKey)) {
        const keyword = this.tableSearchKey.trim()

        if (this.disabledMultiTypeSearch || this.tableSearchType === 'order_id') {
          if (this.$validate.isValidId(keyword)) {
            const id = keyword.substring(1)
            payload.filter_item.push({ key: 'id', value: parseInt(id) })
          } else if (this.$validate.regexNumber(keyword)) {
            payload.filter_item.push({ key: 'id', value: parseInt(keyword) })
          }
        } else {
          payload.filter_item.push({ key: this.tableSearchType, value: keyword })
        }
      }

      const selectedSchool = await this.getUserSelectedSchool()
      if (this.$validate.DataValid(selectedSchool)) {
        payload.center_id = selectedSchool
      }

      if (this.$validate.DataValid(this.tableSearchOrderDate)) {
        payload.date = this.tableSearchOrderDate
        payload.filter_item.push({ key: 'create_date', value: this.tableSearchOrderDate })
      }

      if (payload.filter_item.length < 1) {
        delete payload.filter_item
      }

      return payload
    },
    async getOrderData(regenUrl = true) {
      if (regenUrl === true) {
        this.regenerateURL()
      }

      this.tableLoading = true
      try {
        const payload = await this.getPayload()
        const { data, total } = await this.$Fetcher.GetOrders(payload)

        const list = await this.getOrderPackages(data)
        this.tableData = list.map(el => {
          let items = []
          if (el.order_type === 'course') {
            const group = []
            el.item_datas.forEach(item => {
              if (group.length > 0) {
                const related = group.find(
                  temp => temp[0].course_name === item.course_name && temp[0].course_code === item.course_code,
                )
                if (related) {
                  related.push(item)
                } else {
                  group.push([item])
                }
              } else {
                group.push([item])
              }
            })

            group.forEach(item => {
              items.push(item[0].course_name)
            })
          } else if (el.order_type === 'product') {
            items = el.item_datas.map(item => item.name)
          } else if (el.order_type === 'package') {
            items = [el.package_name]
          }
          return {
            id: el.id,
            order_id: el.id,
            create_date: el.create_date,
            order_type: el.order_type,
            month: el.month,
            order_items: items.join(', '),
            qty:
              el.order_type === 'package'
                ? 1
                : el.item_list.reduce(
                    (count, item) => count + (item && this.$validate.DataValid(item.amount) ? item.amount : 1),
                    0,
                  ),
            payment_image: el.payment_image,
            remark: el.remark,
            total_price: `HK$${this.$formatter.separator(el.subtotal)} ${
              el.status === 'debit' ? this.$t('refundedRemark', { refund: el.debit_amount }) : ''
            }`,
            item_datas: el.item_datas,
            item_list: el.item_list,
            student_id: el.student_id,
            // -- 退款
            refund: el.status === 'debit',
            debit_package_count: el.debit_package_count,
            debit_lesson_ids: el.debit_lesson_ids,
            debit_product_ids: el.debit_product_ids,
            debit_amount: el.debit_amount,
            debit_remark: el.debit_remark,
            school_id: el.school_id,
            student_name: el.student_name,
          }
        })

        this.tableItemTotal = total
      } catch (err) {
        this.$common.error(err)
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },
    getOrderPackages(list) {
      return new Promise(async (resolve, reject) => {
        const packageOrders = list.filter(el => el.order_type === 'package')
        if (packageOrders.length) {
          try {
            const { data } = await this.$Fetcher.GetPackagesByIdList(packageOrders.map(el => el.package_id))
            list.forEach(item => {
              if (item.order_type === 'package') {
                const found = data.find(el => el.id === item.package_id)
                if (found) {
                  item.package_name = found.name
                }
              }
            })
            resolve(list)
          } catch (err) {
            this.$common.error(err)
            resolve(list)
          }
        } else {
          resolve(list)
        }
      })
    },
    searchKeyword(keyword) {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchKey = keyword.trim()
      this.getOrderData()
    },
    searchOrderDate(date) {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchOrderDate = date
      this.getOrderData()
    },
    clearSearch() {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchKey = ''
      this.tableSearchOrderDate = ''
      this.tableSearchId = ''
      this.tableSearchType = this.sid !== null ? 'order_id' : this.searchTypeOptions[0].value
      this.getOrderData()
    },
    refreshData() {
      this.tableLimit = 10
      this.tablePage = 1
      this.tableItemTotal = 0
      this.getOrderData()
    },

    onTableOptionsChange(options) {
      if (options.itemsPerPage !== this.tableLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      this.tableLimit = options.itemsPerPage
      this.getOrderData()
    },

    async exportData(prePayload = null, count = 0) {
      const userType = this.getUserType()
      if (userType === 'student') {
        return
      }
      if (this.exportLoading || this.downloadLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.exportLoading = true
      let payload = {}
      if (prePayload) {
        payload = prePayload
      } else {
        if (this.$validate.DataValid(this.tableSearchOrderDate)) {
          payload['start_date'] = this.tableSearchOrderDate
          payload['end_date'] = this.tableSearchOrderDate
        }
        if (this.sid !== null) {
          payload['student_id'] = this.sid;
        } else if (this.$validate.DataValid(this.tableSearchType) && this.$validate.DataValid(this.tableSearchKey)) {
          const student = await this.getStudentByFilter()
          if (student) {
            payload['student_id'] = student.id
          }
        }

        const selectedSchool = await this.getUserSelectedSchool()
        if (this.$validate.DataValid(selectedSchool)) {
          payload.center_id = selectedSchool
        }
      }

      try {
        const file = await this.$Fetcher.ExportOrderReport(payload)

        var download = document.createElement('a')
        download.download = file
        download.href = `${this.$reportPath}${file}`
        download.style.display = 'none'
        download.target = '_blank'
        document.body.appendChild(download)
        download.click()
        download.remove()

        if (this.exportTimeOut !== null) {
          clearTimeout(this.exportTimeOut)
        }
        this.exportLoading = false
      } catch (err) {
        this.$common.error(err)
        if (count < 5) {
          this.exportTimeOut = setTimeout(async () => {
            this.exportLoading = false
            count++
            await this.exportData(payload, count)
          }, 100)
        } else {
          this.setDialogMessage({
            title: 'message.exportFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.exportLoading = false
        }
      }
    },
    getStudentByFilter() {
      return new Promise(async (resolve, reject) => {
        try {
          const payload = {
            filter_limit: 1,
            filter_page: 0,
            filter_item: [],
          }
          if (this.tableSearchType === 'student_name') {
            payload.filter_item.push({ key: 'name', value: this.tableSearchKey })
          } else if (this.tableSearchType === 'phone') {
            payload.filter_item.push({ key: 'phone', value: this.tableSearchKey })
          }
          if (payload.filter_item.length) {
            const { data } = await this.$Fetcher.GetStudents(payload)
            resolve(data[0])
          } else {
            resolve(null)
          }
        } catch (err) {
          resolve(null)
        }
      })
    },

    async exportInvoice(orderId, count = 0) {
      if (this.exportLoading || this.downloadLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.downloadLoading = true
      try {
        const file = await this.$Fetcher.ExportOrderInvoice(orderId)

        var download = document.createElement('a')
        download.download = file
        download.href = `${this.$mediaPath}${file}`
        download.style.display = 'none'
        download.target = '_blank'
        document.body.appendChild(download)
        download.click()
        download.remove()

        if (this.downloadTimeOut !== null) {
          clearTimeout(this.downloadTimeOut)
        }
        this.downloadLoading = false
      } catch (err) {
        this.$common.error(err)
        if (count < 5) {
          this.downloadTimeOut = setTimeout(async () => {
            this.downloadLoading = false
            count++
            await this.exportInvoice(orderId, count)
          }, 100)
        } else {
          this.setDialogMessage({
            title: 'message.downloadFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.downloadLoading = false
        }
      }
    },

    getQuery() {
      if (Object.keys(this.query).length > 0) {
        if (this.$validate.DataValid(this.query.searchType)) {
          this.tableSearchType = this.query.searchType
        }

        if (this.$validate.DataValid(this.querySearch)) {
          this.tableSearchKey = this.querySearch
        }

        if (this.$validate.DataValid(this.query.orderDate)) {
          this.tableSearchOrderDate = this.query.orderDate
        }

        if (this.$validate.DataValid(this.query.page) && this.$validate.regexNumber(this.query.page)) {
          this.tablePage = parseInt(this.query.page)
        }

        if (this.$validate.DataValid(this.query.limit) && this.$validate.regexNumber(this.query.limit)) {
          this.tableLimit = parseInt(this.query.limit)
        }
      }
    },

    regenerateURL() {
      const q = {
        searchType: this.disabledMultiTypeSearch ? 'order_id' : this.tableSearchType,
        search: this.tableSearchKey.trim(),
        orderDate: this.tableSearchOrderDate,
        page: this.tableLimit !== 10 || this.tablePage !== 1 ? this.tablePage : null,
        limit: this.tableLimit !== 10 || this.tablePage !== 1 ? this.tableLimit : null,
      }
      const newQuery = this.$common.diffQuery(q, this.query)
      if (newQuery) {
        this.$router.replace({ query: newQuery })
      }
    },

    // ============== 退款 ==============
    async openRefund(orderId) {
      // 未退款 用
      const confirm = await this.$refs.confirmRefundDialog.show()
      if (confirm) {
        this.refundData.order_id = -1
        this.refundData.price = ''
        this.refundData.items = []
        this.refundData.remark = ''
        this.refundData.lesson_count = ''
        this.refundOrderType = ''
        await this.$refs.refundFormDialog.resetForm()

        const found = this.tableData.find(el => el.id === orderId)
        if (found) {
          this.refundTableData = []
          this.refundOrderType = found.order_type
          if (this.refundOrderType === 'course') {
            this.refundTableData = found.item_list.map(item => {
              const obj = {
                id: item.id,
                course_name: '',
                start_time: '',
                end_time: '',
                date: '',
              }
              const target = found.item_datas.find(el => el.id === item.id)
              if (target) {
                obj.course_name = target.course_name
                obj.date = target.date
                obj.start_time = target.start_time
                obj.end_time = target.end_time
              }
              return obj
            })
          } else if (this.refundOrderType === 'product') {
            this.refundTableData = found.item_list.map(item => {
              const obj = {
                id: item.id,
                qty: item.amount,
                product_name: '',
              }
              const target = found.item_datas.find(el => el.id === item.id)
              if (target) {
                obj.product_name = target.name
              }
              return obj
            })
          }

          this.$refs.refundFormDialog.setEditId(orderId)
          this.$refs.refundFormDialog.show()
        }
      }
    },
    async handleRefundSubmit() {
      if (
        (this.refundOrderType === 'course' || this.refundOrderType === 'product') &&
        this.refundData.items.length < 1
      ) {
        this.setDialogMessage({
          title: 'message.operationalError',
          message: this.refundOrderType === 'product' ? 'message.atLeastOneProduct' : 'message.chooseAtLeastOneLesson',
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$refs.refundFormDialog.setLoading(false)
        return
      }

      const orderId = this.$refs.refundFormDialog.getEditId()

      if (this.refundOrderType === 'package') {
        const targetOrder = this.tableData.find(el => el.id === orderId)
        if (targetOrder) {
          const packageQuata = await this.$Fetcher.GetStudentPackageQuota(targetOrder.student_id)
          if (packageQuata <= 0 || packageQuata < parseInt(this.refundData.lesson_count)) {
            this.setDialogMessage({
              title: 'message.refundFail',
              message: 'message.noMorePackageQuotaInRefund',
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
            this.$refs.refundFormDialog.setLoading(false)
            return
          }
        } else {
          this.$refs.refundFormDialog.setLoading(false)
          return
        }
      }

      const payload = {
        amount: parseFloat(this.refundData.price),
        package_count: 0,
        lesson_ids: [],
        product_ids: [],
        debit_remark: this.refundData.remark,
      }

      if (this.refundOrderType === 'package') {
        payload.package_count = parseInt(this.refundData.lesson_count)
      } else if (this.refundOrderType === 'course') {
        payload.lesson_ids = this.refundData.items.map(el => el.id)
      } else if (this.refundOrderType === 'product') {
        this.refundData.items.forEach(item => {
          const found = this.refundTableData.find(el => el.id === item.id)
          if (found) {
            payload.product_ids.push({ id: found.id, amount: found.qty })
          }
        })
      }

      try {
        const data = await this.$Fetcher.RefundOrder(orderId, payload)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.refundSuccess',
          type: 'success',
          refresh: false,
          redirect: '',
        })
        this.$refs.refundFormDialog.hide()
        this.getOrderData(false)
      } catch (err) {
        this.setDialogMessage({
          title: 'message.refundFail',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$refs.refundFormDialog.setLoading(false)
      }
    },

    openRefundDetails(orderId) {
      // 已退款 用
      this.refundData.order_id = -1
      this.refundData.price = ''
      this.refundData.items = []
      this.refundData.remark = ''
      this.refundData.lesson_count = ''
      this.refundOrderType = ''

      const found = this.tableData.find(el => el.id === orderId)
      if (found) {
        this.refundData.order_id = found.id
        this.refundTableData = []
        this.refundOrderType = found.order_type

        if (this.refundOrderType === 'course') {
          this.refundTableData = found.debit_lesson_ids.map(lessonId => {
            const obj = {
              id: lessonId,
              course_name: '',
              start_time: '',
              end_time: '',
              date: '',
            }
            const target = found.item_datas.find(el => el.id === lessonId)
            if (target) {
              obj.course_name = target.course_name
              obj.date = target.date
              obj.start_time = target.start_time
              obj.end_time = target.end_time
            }
            return obj
          })
        } else if (this.refundOrderType === 'product') {
          this.refundTableData = found.debit_product_ids.map(item => {
            const obj = {
              id: item.id,
              qty: item.amount,
              product_name: '',
            }
            const target = found.item_datas.find(el => el.id === item.id)
            if (target) {
              obj.product_name = target.name
            }
            return obj
          })
        }

        this.refundData.price = found.debit_amount
        this.refundData.remark = found.debit_remark
        this.refundData.lesson_count = found.debit_package_count
        this.refundDetailsDialogOpen = true
      }
    },

    async downloadRefundInvoice() {
      if (this.refundDownloading) {
        return
      }

      const found = this.tableData.find(el => el.id === this.refundData.order_id)
      if (found) {
        this.refundDownloading = true
        try {
          const data = await this.$Fetcher.GetSchoolById(found.school_id)
          const filename = `tutoring_debit_${data.school_code}-${this.$formatter.pad(this.refundData.order_id, 4)}.pdf`

          var download = document.createElement('a')
          download.download = filename
          download.href = `${this.$mediaPath}${filename}`
          download.style.display = 'none'
          download.target = '_blank'
          document.body.appendChild(download)
          download.click()
          download.remove()
        } catch (err) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.downloadFail',
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } finally {
          this.refundDownloading = false
        }
      }
    },

    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: OrderList')
      this.getQuery()
      await this.getOrderData(false)
    },
  },

  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.tableLoading = true
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  created() {
    const userType = this.getUserType()
    if (this.disabledMultiTypeSearch) {
      this.tableSearchType = 'order_id'
    }
    if (!userType) {
      this.canRefund = false
    } else {
      if (
        cannotAccessList['refund'] &&
        cannotAccessList['refund'].length &&
        cannotAccessList['refund'].includes(userType)
      ) {
        this.canRefund = false
      }
    }
  },
}
</script>
